import moment from 'moment'
var dateFormat = {
  methods: {
    dateFormatter(dateStr, formatStr) {
      var date = moment(dateStr).format(formatStr)
      return date
    },
  },
}

export default dateFormat
