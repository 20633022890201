<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-6">
      <div class="row d-flex justify-content-center">
        <div class="col-6 p-3">
          <div class="small">{{ $t('name.label') }}</div>
          <h6 class="font-weight-bold">{{ name }}</h6>
        </div>
        <div class="col-6 p-3">
          <div class="small">{{ $t('phone_number.label') }}</div>
          <h6 class="font-weight-bold">{{ phone }}</h6>
        </div>
        <div class="col-6 p-3">
          <div class="small">{{ $t('date_of_birth.label') }}</div>
          <h6 class="font-weight-bold">{{ dob | DateFormatter }}</h6>
        </div>
        <div class="col-6 p-3">
          <div class="small">{{ $t('province_of_work.label') }}</div>
          <h6 class="font-weight-bold">{{ province_work }}</h6>
        </div>
        <div class="col-6 p-3">
          <div class="small">{{ $t('province_of_residence.label') }}</div>
          <h6 class="font-weight-bold">{{ province_resident }}</h6>
        </div>
        <div class="col-6 p-3">
          <div class="small">{{ $t('income_type.label') }}</div>
          <h6 class="font-weight-bold">{{ income_type }}</h6>
        </div>
        <div class="col-12 p-3">
          <div class="small">{{ $t('salary.label') }}</div>
          <h6 class="font-weight-bold">{{ salary }} {{ $t('bahts') }}</h6>
        </div>
        <!-- <div class="col-6"></div> -->
        <div class="col-12 p-2">
          <div class="row p-0 m-0">
            <div class="col-12 p-2">
              <router-link to="/edit-information">
                <b-button pill variant="primary w-100">{{ $t('edit_basic_information') }}</b-button>
              </router-link>
            </div>
            <div class="col-12 p-2">
              <router-link to="/change-phone-number">
                <b-button pill variant="primary w-100">{{ $t('change_phone_number_label') }}</b-button>
              </router-link>
            </div>
            <div class="col-12 p-2">
              <b-button pill variant="outline-primary w-100" @click="logout()">{{ $t('header_logout') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <loading :is-busy="isBusy"></loading>
    </div>
  </div>
</template>

<script>
import AccountService from '@/api/service/AccountService'
import CommonService from '@/api/service/CommonService'
import Loading from '@/components/Loading.vue'
export default {
  name: 'BasicInformation',
  components: {
    Loading,
  },
  data() {
    return {
      isBusy: false,
      name: null,
      phone: null,
      dob: null,
      province_work: null,
      province_resident: null,
      income_type: null,
      salary: null,
      social_media: null,
      province_work_options: [],
      province_resident_options: [],
      income_type_options: [],
      nav_list: [
        { key: 1, value: 'basic_information' },
        { key: 2, value: 'documents' },
        { key: 3, value: 'loan_history' },
      ],
    }
  },
  created() {
    this.getInitData()
  },
  methods: {
    async getInitData() {
      try {
        this.isBusy = true
        const provinceResponse = await CommonService.getProvince()
        const incomeTypeResponse = await CommonService.getIncomeType()

        const provinces = provinceResponse.data.data

        provinces.forEach((province) => {
          if (province.status === 'show') {
            this.province_work_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
            this.province_resident_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
          }
        })

        this.income_type_options = incomeTypeResponse.data.data

        this.getUserProfile()

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getUserProfile() {
      try {
        this.isBusy = true
        const res = await AccountService.getUserProfile()
        this.isBusy = false
        if (res.status == 200) {
          const profileResponse = res.data.data
          this.name = profileResponse.name
          this.phone = profileResponse.phone_number
          this.dob = profileResponse.dob
          // this.province_work = profileResponse.province_of_work;
          // this.province_resident = profileResponse.province_of_resident;
          this.salary = profileResponse.salary
          // this.social_media = profileResponse.email;
          this.income_type = profileResponse.income_type

          this.initIncomeType(profileResponse.income_type.toLowerCase())
          this.initProvinceWork(profileResponse.province_of_work)
          this.initProvinceResident(profileResponse.province_of_resident)
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    initIncomeType(key) {
      this.income_type_options.forEach((income_type) => {
        if (income_type.key === key) {
          if (this.$i18n.locale == 'en') {
            this.income_type = income_type.name_en
          } else if (this.$i18n.locale == 'th') {
            this.income_type = income_type.name_th
          } else {
            this.income_type = income_type.name_mm
          }
        }
      })
    },
    initProvinceWork(id) {
      this.province_work_options.forEach((province) => {
        if (province.id == id) {
          this.province_work = province.name
        }
      })
    },
    initProvinceResident(id) {
      this.province_resident_options.forEach((province) => {
        if (province.id == id) {
          this.province_resident = province.name
        }
      })
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('register/reset')
      this.$router.push({ name: 'landing' })
    },
  },
}
</script>

<style></style>
