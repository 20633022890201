<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 p-0">
            <FormRenderer v-if="formData" v-model="formInputData" :form-configuration="formData" />
            <div v-else class="d-flex justify-content-center">
              <h6 class="text-danger">{{ error_message }}</h6>
            </div>
          </div>
        </div>
        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import { FormRenderer } from 'v-form-builder'
import FormService from '@/api/service/FormService'
import Loading from '@/components/Loading.vue'

import '@/assets/scss/v-form-builder.scss'

import formDataMixin from '@/mixins/formDataMixin'

import { bus } from '@/main.js'

export default {
  components: { FormRenderer, Loading },
  mixins: [formDataMixin],
  data() {
    return {
      formData: null,
      formInputData: null,
      getInputData: null,
      isBusy: false,
      first_merge: true,
      error_message: null,
      showDocumentsList: [
        'control-2249e082-27cc-4021-a9ad-eb90557f3019',
        'control-ca3ca42a-433f-43a7-a1f4-237aed787bfb',
        'control-f7185ee5-5fd9-4226-a00f-248068d176d6',
        'control-0e65ef3c-42a2-45e0-9dc4-555525c8fe73',
        'control-79eaa386-e897-4466-b47b-811d591d92df',
        'control-3c32b4b2-77dd-4c38-8c95-4edc6a50c7bf',
      ],
    }
  },
  watch: {
    formInputData: {
      handler: function () {
        while (this.first_merge && this.getInputData) {
          this.formInputData = this.getInputData
          this.first_merge = false
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.$formEvent.$off()
    this.$formEvent.$on()
  },
  created() {
    this.getFormData()
    bus.$on('uploadDocument', (data) => {
      if (data === 'uploaded') {
        this.getDocumentList()
      }
    })
  },
  methods: {
    async getFormData() {
      this.isBusy = true
      try {
        const res = await FormService.getFormData()
        this.isBusy = false
        if (res.status == 200) {
          const formResponseData = res.data.data
          if (formResponseData.input_data) {
            this.getInputData = JSON.parse(formResponseData.input_data.input_data)
          }
          if (formResponseData.pages) {
            formResponseData.pages.forEach((page) => {
              if (page.type === 'documents') {
                this.formData = this.bindLocale(JSON.parse(page.form_data), true, this.showDocumentsList)
                this.version_id = page.version_id

                this.getDocumentList()
              }
            })
          }
          /**
           * After finish api call, added javascript click event
           */
          this.addClickEvent()
          this.removeZero()
        }
      } catch (err) {
        this.isBusy = false
        this.error_message = err
        this.showAlert(err)
      }
    },

    async getDocumentList() {
      try {
        this.isBusy = true
        const resDocument = await FormService.getDocumentFormData({
          version_id: this.version_id,
        })

        this.isBusy = false

        if (resDocument.status == 200) {
          const uploadedResponse = resDocument.data.data
          this.$store.dispatch('form/saveUploadedDocument', {
            uploadedDocumentList: uploadedResponse,
          })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
