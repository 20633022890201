<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-8 col-lg-6 mb-3">
      <div v-if="!isBusy">
        <div v-for="(document, index) in documentList" :key="index" class="pt-2">
          <div class="p-2 text-right text-primary">
            {{ document.created_at | DateFormatter }}
          </div>
          <div v-if="checkURL(document.file)">
            <b-img :src="document.file" fluid alt="document.filename"></b-img>
          </div>
          <div v-else>
            <a :href="document.file" target="_blank" style="word-break: break-word">
              {{ document.filename }}
            </a>
            <!-- <div
              class="
                border border-primary
                rounded
                d-flex
                justify-content-between
                content-items-center
              "
            >
              <div class="p-2">
                {{ document.filename.slice(0, 15) }}
              </div>
              <div class="p-2 border-left border-primary text-primary">
                <b-button variant="bg-transparent text-primary p-0 m-0" :href="document.file" target="_blank">
                  {{ $t('view_pdf') }}
                </b-button>
              </div>
            </div> -->
          </div>
        </div>
        <div v-if="documentList.length <= 0" class="text-center mt-5">
          {{ $t('no_additional_document') }}
        </div>
      </div>
    </div>
    <loading :is-busy="isBusy"></loading>
  </div>
</template>

<script>
import AdditionalDocumentService from '@/api/service/AdditionalDocumentService'
import LoanService from '@/api/service/LoanService'
import Loading from '@/components/Loading.vue'
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isBusy: false,
      documentList: [],
    }
  },
  created() {
    this.getLoanApplication()
  },
  methods: {
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          const loanApplicationId = loanResponse.id
          this.getAdditionalDocument(loanApplicationId)
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getAdditionalDocument(loanApplicationId) {
      try {
        this.isBusy = true
        const res = await AdditionalDocumentService.getAdditionalDocument({
          loan_application_id: loanApplicationId,
        })
        this.isBusy = false
        if (res.status == 200) {
          this.documentList = res.data.data
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    checkURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null
    },
  },
}
</script>
<<<<<<< .merge_file_BhcjBZ =======

<style></style>
>>>>>>> .merge_file_nZbTE5
