import client from '@/api/AxiosInstance.js'
export default {
  getBranch() {
    return client.get('/data/branches')
  },
  getProvince() {
    return client.get('/data/provinces')
  },
  getIncomeType() {
    return client.get('/data/income-types')
  },
  getAnnouncement() {
    return client.get('/data/announcements')
  },
}
