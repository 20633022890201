var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"col-12 col-md-6 row p-0 m-0"},[_vm._l((_vm.loan_histories),function(loan_history){return _c('div',{key:loan_history.id,staticClass:"col-12 p-3"},[_c('b-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex flex-wrap align-items-center justify-content-between"},[_c('h6',[_vm._v(_vm._s(loan_history.application.contract_no_ref))]),_c('h6',{staticClass:"text-primary text-right font-weight-bold cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.$t('view_barcode'))},on:{"click":function($event){return _vm.showModel(loan_history.qrcode)}}})]),_c('div',{staticClass:"mt-3 col-12"},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('loan_status')))]),_c('h6',{staticClass:"mt-2"},[(
                  loan_history.loan_application_status == 'pending' ||
                  loan_history.loan_application_status == 'complete-review'
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('pending_loan'))+" ")]):(
                  loan_history.loan_application_status == 'pre-approved' ||
                  loan_history.loan_application_status == 'approved' ||
                  loan_history.loan_application_status == 'agree' ||
                  loan_history.loan_application_status == 'ready-to-disburse' ||
                  loan_history.loan_application_status == 'disburse-approved'
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('pre_approve_loan'))+" ")]):(loan_history.loan_application_status == 'disbursed')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('approve_loan'))+" ")]):(loan_history.loan_application_status == 'reject')?_c('div',[_vm._v(" "+_vm._s(_vm.$t(''))+" ")]):_vm._e()])]),_c('div',{staticClass:"mt-3 col-12 col-md-6"},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('loan_size')))]),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(loan_history.principle_amount)+" "+_vm._s(_vm.$t('bahts'))+" ")])]),_c('div',{staticClass:"mt-3 col-12 col-md-6"},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('loan_term')))]),_c('h6',{staticClass:"mt-2"},[_vm._v(_vm._s(loan_history.term_period)+" "+_vm._s(_vm.$t('months')))])]),_c('div',{staticClass:"mt-3 col-12 col-md-6"},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('first_payment_date')))]),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm._f("DateFormatter")(loan_history.first_repayment_date))+" ")])]),_c('div',{staticClass:"mt-3 col-12 col-md-6"},[_c('div',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('last_payment_date')))]),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm._f("DateFormatter")(loan_history.last_repayment_date))+" ")])]),_c('div',{staticClass:"mt-3 col-12"},[_c('router-link',{attrs:{"to":{
                name: 'loan-history-detail',
                params: { id: loan_history.id },
              }}},[_c('b-button',{staticClass:"w-100 mt-2",attrs:{"pill":"","variant":"outline-primary"}},[_vm._v(_vm._s(_vm.$t('view_loan_detail')))])],1)],1)])])],1)}),(_vm.loan_histories.length === 0)?_c('div',{staticClass:"col-12 text-center mt-5"},[_vm._v(" "+_vm._s(_vm.$t('no_loan_history'))+" ")]):_vm._e()],2),(_vm.hasNextPage)?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"mt-2 px-4",attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.getLoanHistory()}}},[_vm._v(_vm._s(_vm.$t('view_next_page')))])],1):_vm._e(),_c('loading',{attrs:{"is-busy":_vm.isBusy}}),_c('b-modal',{attrs:{"id":"modal_barcode","centered":"","scrollable":"","size":"sm","hide-header":"","hide-footer":"","content-class":"border-0 w-auto"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"p-4"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.paymentReferencePhoto}}),_c('div',{staticClass:"mt-4",staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm.paymentReferenceNumber))])]),_c('input',{attrs:{"id":"testing-code","type":"hidden"},domProps:{"value":_vm.paymentReferenceNumber}}),_c('div',{staticClass:"bg-white mx-auto"},[_c('b-button',{staticClass:"px-4 my-3",attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.copyToClipboard()}}},[_vm._v(_vm._s(_vm.$t('copy_number')))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }