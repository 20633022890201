<template>
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-6 row p-0 m-0">
      <!-- <div class="col-12">
        <b-badge
          v-if="loan_status"
          pill
          variant="bg-secondary-light"
          class="text-primary bg-secondary-light border border-secondary py-2 px-3 mr-2"
          >{{ loan_status.name }}</b-badge
        >
        <b-badge
          v-if="date_range"
          pill
          variant="bg-secondary-light"
          class="text-primary bg-secondary-light border border-secondary py-2 px-3"
          >{{ date_range }}</b-badge
        >
      </div> -->

      <div v-for="loan_history in loan_histories" :key="loan_history.id" class="col-12 p-3">
        <b-card>
          <div class="row">
            <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
              <h6>{{ loan_history.application.contract_no_ref }}</h6>
              <h6
                class="text-primary text-right font-weight-bold cursor-pointer"
                @click="showModel(loan_history.qrcode)"
                v-html="$t('view_barcode')"
              ></h6>
            </div>
            <div class="mt-3 col-12">
              <div class="small">{{ $t('loan_status') }}</div>
              <h6 class="mt-2">
                <div
                  v-if="
                    loan_history.loan_application_status == 'pending' ||
                    loan_history.loan_application_status == 'complete-review'
                  "
                >
                  {{ $t('pending_loan') }}
                </div>
                <div
                  v-else-if="
                    loan_history.loan_application_status == 'pre-approved' ||
                    loan_history.loan_application_status == 'approved' ||
                    loan_history.loan_application_status == 'agree' ||
                    loan_history.loan_application_status == 'ready-to-disburse' ||
                    loan_history.loan_application_status == 'disburse-approved'
                  "
                >
                  {{ $t('pre_approve_loan') }}
                </div>
                <div v-else-if="loan_history.loan_application_status == 'disbursed'">
                  {{ $t('approve_loan') }}
                </div>
                <div v-else-if="loan_history.loan_application_status == 'reject'">
                  {{ $t('') }}
                </div>
              </h6>
            </div>
            <div class="mt-3 col-12 col-md-6">
              <div class="small">{{ $t('loan_size') }}</div>
              <h6 class="mt-2">
                {{ loan_history.principle_amount }}
                {{ $t('bahts') }}
              </h6>
            </div>
            <div class="mt-3 col-12 col-md-6">
              <div class="small">{{ $t('loan_term') }}</div>
              <h6 class="mt-2">{{ loan_history.term_period }} {{ $t('months') }}</h6>
            </div>
            <div class="mt-3 col-12 col-md-6">
              <div class="small">{{ $t('first_payment_date') }}</div>
              <h6 class="mt-2">
                {{ loan_history.first_repayment_date | DateFormatter }}
              </h6>
            </div>
            <div class="mt-3 col-12 col-md-6">
              <div class="small">{{ $t('last_payment_date') }}</div>
              <h6 class="mt-2">
                {{ loan_history.last_repayment_date | DateFormatter }}
              </h6>
            </div>
            <div class="mt-3 col-12">
              <router-link
                :to="{
                  name: 'loan-history-detail',
                  params: { id: loan_history.id },
                }"
              >
                <b-button class="w-100 mt-2" pill variant="outline-primary">{{ $t('view_loan_detail') }}</b-button>
              </router-link>
            </div>
          </div>
        </b-card>
      </div>
      <div v-if="loan_histories.length === 0" class="col-12 text-center mt-5">
        {{ $t('no_loan_history') }}
      </div>
    </div>
    <div v-if="hasNextPage" class="text-center">
      <b-button class="mt-2 px-4" pill variant="primary" @click="getLoanHistory()">{{ $t('view_next_page') }}</b-button>
    </div>
    <loading :is-busy="isBusy"></loading>
    <b-modal id="modal_barcode" centered scrollable size="sm" hide-header hide-footer content-class="border-0 w-auto">
      <div class="text-center">
        <div class="p-4">
          <img :src="paymentReferencePhoto" class="w-100" />
          <div style="font-size: 11px" class="mt-4">{{ paymentReferenceNumber }}</div>
        </div>
        <!-- <barcode v-bind:value="paymentReferenceNumber" class="w-100"> </barcode> -->
        <input id="testing-code" type="hidden" :value="paymentReferenceNumber" />
        <div class="bg-white mx-auto">
          <b-button pill class="px-4 my-3" variant="primary" @click="copyToClipboard()">{{
            $t('copy_number')
          }}</b-button>
          <!-- <b-button pill variant="outline-primary" size="sm" class="ml-3" @click="downloadReferenceNumber()">
            <b-icon-download></b-icon-download>
          </b-button> -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import '@/assets/scss/vue-datepicker.min.css'
import '@/assets/scss/vue-multiselect.min.scss'
import dateFormat from '@/mixins/dateFormat.js'
import LoanService from '@/api/service/LoanService'
import Loading from '@/components/Loading.vue'
const date = new Date()
export default {
  name: 'LoanHistory',
  components: { Loading },
  mixins: [dateFormat],
  data() {
    return {
      locale: {
        lang: 'en',
      },
      date,
      maxDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      format: 'MM-DD-YYYY',
      loan_status: null,
      loan_status_options: [{ name: 'Pending' }, { name: 'Pre Approve' }, { name: 'Approve' }],
      start_date: null,
      end_date: null,
      date_range: null,
      loan_histories: [],
      isBusy: false,
      hasNextPage: false,
      currentPage: 1,
      paymentReferenceNumber: null,
      paymentReferencePhoto: null,
    }
  },
  watch: {
    date: function (val) {
      if (val) {
        this.start_date = val.start ? this.dateFormatter(val.start, 'DD-MM-YYYY') : null
        this.end_date = val.end ? this.dateFormatter(val.end, 'DD-MM-YYYY') : null
        this.date_range = this.start_date && this.end_date ? this.start_date + ' to ' + this.end_date : null
      }
    },
  },
  created() {
    this.getLoanHistory()
  },
  methods: {
    showModel(qrcode) {
      this.$bvModal.show('modal_barcode')
      this.paymentReferenceNumber = qrcode.string
      this.paymentReferencePhoto = qrcode.photo
    },
    async getLoanHistory() {
      try {
        this.isBusy = true
        const res = await LoanService.loanHistory({
          page: this.currentPage,
        })

        this.loan_histories.push(...res.data.data)
        this.hasNextPage = res.data.meta.has_next_page
        if (this.hasNextPage) {
          this.currentPage++
        }

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    showDatePicker() {
      this.$bvModal.show('modal-date-picker')
    },
    applyFilter() {
      this.$bvModal.hide('modal-filter')
      this.getLoanHistory()
    },
    removeFilter() {
      this.date = new Date()
      this.loan_status = null
      this.$bvModal.hide('modal-filter')
    },
    copyToClipboard() {
      this.$bvModal.hide('modal_barcode')
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.showAlert('ငွေပေးချေမှုရည်ညွှန်းနံပါတ်ကို ကူးယူပြီးပါပြီ')
      } catch (err) {
        // this.showAlert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
}
</script>

<style>
/* .modal-content {
  width: auto !important;
} */
</style>
