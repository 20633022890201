import client from '@/api/AxiosInstance.js'
export default {
  paymentSchedule() {
    return client.post('/loan/payment-schedule')
  },

  //  'mmlo' => 'mmlo',
  // 'mmbm' => 'mmbm',
  // 'thlo' => 'thlo',

  // 'pending' => 'pending',
  // 'complete_review' => 'complete-review',
  // 'pre_approved' => 'pre-approved',
  // 'approved' => 'approved',
  // 'agree' => 'agree',
  // 'ready_to_disburse' => 'ready-to-disburse',
  // 'disburse_approved' => 'disburse-approved',
  // 'disbursed' => 'disbursed',
  // 'complete' => 'complete',
  // 'reject' => 'reject',
  loanApplication() {
    return client.get('/loan/application')
  },

  loanInformation() {
    return client.get('/loan/information')
  },

  loanHistory(params) {
    return client.get('/loan-history', { params: params })
  },

  loanHistoryDetail(params) {
    return client.get('/loan-history/' + params.id + '/schedule')
  },

  cancelLoanApplication() {
    return client.get('/loan/application/cancel')
  },

  branchAppointment(params) {
    return client.post('loan/branch/appointment', {
      date: params.date,
      time: params.time,
    })
  },
}
