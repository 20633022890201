<template>
  <div class="bg-white white-space-nowrap w-100 overflow-x-scroll">
    <div
      v-for="nav in nav_list"
      :key="nav.key"
      class="d-inline-block p-2 px-3 text-center cursor-pointer"
      :class="[active === nav.key ? style.active : '', type == 'full' ? width : '']"
      @click="$emit('click', nav.key)"
    >
      <div class="m-0">{{ $t(nav.value) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  model: {
    prop: 'active',
    event: 'click',
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    nav_list: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      style: {
        active: 'border-bottom border-primary border-2 text-primary',
      },
    }
  },
  computed: {
    width() {
      switch (this.nav_list.length) {
        case 1:
          return 'col-12 col-md-auto'
        case 2:
          return 'col-6 col-md-auto'
        case 3:
          return 'col-4 col-md-auto'
        case 4:
          return 'col-3 col-md-auto'
        default:
          return 'col-3 col-md-auto'
      }
    },
  },
}
</script>

<style></style>
