<template>
  <div class="container" fluid="lg">
    <div class="row sticky-top">
      <nav-bar v-model="active" :nav_list="nav_list" @click="clickNavBar"></nav-bar>
    </div>

    <basic-information v-if="active == 1"></basic-information>
    <documents v-if="active == 2"></documents>
    <additional-document v-if="active == 3"></additional-document>
    <loan-history v-if="active == 4"></loan-history>
  </div>
</template>

<script>
import BasicInformation from './BasicInformation.vue'
import LoanHistory from './LoanHistory.vue'
import Documents from './Documents.vue'
import AdditionalDocument from './AdditionalDocument.vue'
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    BasicInformation,
    Documents,
    LoanHistory,
    AdditionalDocument,
    NavBar,
  },
  data() {
    return {
      active: 1,
      style: {
        active: 'border-bottom border-primary border-2 text-primary',
      },
      nav_list: [
        { key: 1, value: 'header_basic_information', routeName: 'basic-information' },
        { key: 2, value: 'header_documents', routeName: 'documents' },
        { key: 3, value: 'header_additional_document', routeName: 'additional-documents' },
        { key: 4, value: 'header_loan_history', routeName: 'loan-history' },
      ],
    }
  },
  watch: {
    '$route.params.type': {
      handler: function (val) {
        this.getParams(val)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getParams(this.$route.params.type)
  },
  methods: {
    getParams(params) {
      switch (params) {
        case 'basic-information':
          this.active = 1
          break
        case 'documents':
          this.active = 2
          break
        case 'additional-documents':
          this.active = 3
          break
        case 'loan-history':
          this.active = 4
          break
        default:
          this.active = 1
          break
      }
    },
    clickNavBar(index) {
      this.$router.push({ name: 'profile', params: { type: this.nav_list[index - 1].routeName } })
    },
  },
}
</script>

<style>
.s-content {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

/* .s-body {
  display: d-inline-block;
} */
</style>
